<template>
    <div class="content d-flex align-items-center justify-content-center">
        <div class="aside-right ">
            <div class="card" v-if="card.confirmCodeCard" @click="error = ''">
                <div class="card-body">
                    <h4 class="card-title">Confirm SignUp</h4>
                    <div class="fields">
                        <label>Username</label>
                        <InputText type="text" :value="username" readonly="true"/>
                        <label class="mt-4" type="text">Confirmation Code</label>
                        <InputText type="text" v-model="code"/>
                        <span v-if="error != ''">{{error.message}}</span>
                    </div>
                    <div class="login-btn mt-4">
                        <h2 class="navigation-link"><b @click="resendCode()">Resend Code</b></h2>   
                        <Button label="Confirm" class="p-button-primary" @click="confirmSignup()"/>
                    </div>
                </div>
            </div>
            <!-- success card -->
            <div class="card" v-if="card.success">
                <div class="card-body">
                    <div class="box">
                        <i class="fas fa-check-circle text-success"></i>
                        <h1>Account Created</h1>
                    </div>
                </div>
                <router-link to="/login"><h2 class="navigation-link text-center">Back to<b>Sign in</b></h2></router-link>
            </div>
        </div> 
         <div class="loader" id="propertiesLoader" v-if = "loader">
            <ProgressSpinner style="width:50px;height:50px" />
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
    export default {
        data(){
            return {
                card: {
                    confirmCodeCard : true,
                    success         : false
                },
                loader  : false,
                code        : "",
                error       : "",
                username    : this.$route.params.username
            }   
        },
        methods:{
             ...mapActions({
                confirmSignupeVue           : "auth/confirmSignUp",
                resendCodeVue               : "auth/resendCode"
            }),
            async confirmSignup() {
                this.loader = true;
                this.error = "";
                try {
                    await this.confirmSignupeVue({
                        username : this.username,
                        code     : this.code
                    })
                    this.loader = false;
                    this.card.success = true;
                    this.card.confirmCodeCard = false;
                } catch (error) {
                    this.loader = false;
                    this.error = error;
                }
            },
            async resendCode() {
                this.loader = true;
                try {
                    this.loader = false;
                    await this.resendCodeVue({
                        username : this.username,
                    })
                    alert("Check Email")
                } catch(error){
                    this.loader = false;
                    this.error = error;
                }

            },
        }
    }
    
</script>

<style lang="scss" scoped>
@import "Auth.scss";
</style>